import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import CTABusiness from "../../general/ctaBusiness"
import FAQList from '../../general/faqsList';
import InnerPageHeader from "./components/innerPageHeader";
import { scrollToElement } from "../../../../utility/utils"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../general/more-for-you";
import { moreForYou2 } from "./features";

import EntryIllustration from '../../../../../assets/img/expense-card-hero-image.svg'
import EntryIllustrationBlur from "../../../../../assets/img/business/blur/expense-card-hero-blur.svg"

import BizExpenseCard from "../../../../../assets/img/business-card.inline.svg"
import StaffIcon from "../../../../../assets/img/expense-staff-icon.inline.svg"
import NoRoadIcon from "../../../../../assets/img/no-road-icon.inline.svg"

import expenseCardIllustration from "../../../../../assets/img/business/expenses/expenseCard-illustration.svg"
import AssigneeCardIllustration from "../../../../../assets/img/business/expenses/assignCard-illustration.svg"
import moneyMixUpIllustration from "../../../../../assets/img/business/expenses/moneyMixUp-illustration.svg"
import deliverCardsIllustration from '../../../../../assets/img/business/expenses/deliverCards-illustration.svg'

import { Img } from "react-image";


const entryContent = {
    title: (
        <span>
            Control your spending with Kuda Business <span className="color-secondary">expense cards!</span>
        </span>
    ),
    subtitle: "Request a dedicated card for each of your expense accounts on Kuda Business to spend smarter and keep your business finances organised.",
    name: "Download Kuda Business",
    url: "https://kudabusiness.onelink.me/epwz/jceed731/",
}

const businessTopFeatures = [
    {
        icon: <BizExpenseCard />,
        text: "Get expense cards delivered to you anywhere in Nigeria."
    },
    {
        icon: <StaffIcon />,
        text: "Assign expense cards to your staff for better financial management."
    },
    {
        icon: <NoRoadIcon />,
        text: "Set spending limits on your expense cards to stay within your budget."
    },
]

const expenseCard = {
    title: "Expense cards will help you know where your money is going.",
    subtitle: "Because each expense card is linked to a specific expense account, you’ll always find it easy to track your spending and stay within your budget.",
    url: "https://kudabusiness.onelink.me/epwz/jceed731/",
    name: "Open A Kuda Business Account",
    illustration: (
        <Img src={expenseCardIllustration} className="kuda-business-image" />

    ),
}

const AssigneeCard = {
    title: "Assign cards to your staff for better financial management.",
    subtitle: "You’ll no longer need to give your staff access to your main account and you can set spending limits for each card so you won’t worry about overspending.",
    url: "https://kudabusiness.onelink.me/epwz/jceed731/",
    name: "Download Kuda Business",
    illustration: (

        <Img src={AssigneeCardIllustration} className="kuda-business-image" />

    ),
}

const moneyMixUp = {
    title: "Avoid money mix-ups with expense cards.",
    subtitle: "Because each expense account has its own transaction history with transaction receipts, you won’t mix up payments made with your expense cards.",
    url: "https://kudabusiness.onelink.me/epwz/jceed731/",
    name: "Open A Kuda Business Account",
    illustration: (

        <Img src={moneyMixUpIllustration} className="kuda-business-image" />

    ),
}

const deliverCards = {
    title: "We’ll deliver your expense cards to you.",
    subtitle: "Whenever you create an expense account on Kuda Business, you can easily request an expense card with delivery and pickup options.",
    url: "https://kudabusiness.onelink.me/epwz/jceed731/",
    name: "Download Kuda Business",
    illustration: (

        <Img src={deliverCardsIllustration} className="kuda-business-image" />

    ),
}

const questions = [
    {
        heading: "What’s an expense card?",
        list: (
            <span className="flex flex-column  mt-2">

                <span className="faq-description f-16 mb-3">
                    An expense card is a debit card linked to a specific expense account on Kuda Business. It makes it easy for you to control spending because it’s not linked to your main business account.
                </span>

                <span className="faq-description f-16">
                    You can assign expense cards to your staff, can track card transactions as they’re made, and maintain financial clarity across the different parts of your business.
                </span>
            </span>),
        index: 1
    },
    {
        heading: "Can I set a spending limit on a Kuda Business expense card?",
        list: (
            <div className="flex flex-column  mt-2">
                <span className="faq-description f-16">
                    Yes, you can set a daily spending limit on a Kuda Business and choose where the card works - online, on ATMs, and/or on POS terminals.
                </span>
            </div>),
        index: 2
    },
    {
        heading: "How do I request an expense card?",
        list: (
            <span className="flex flex-column  mt-2">
                <span className="faq-description f-16">To request an expense card on the Kuda Business app, follow the steps below:

                </span>

                <span className="pt-3 career-message-margin-bottom ">
                    <div className="flex flex-column  leading-md-6 f-md-16">
                        <div className="mb-3">1. Sign in to your Kuda Business app. If you already have an expense account that you want to request an expense card for, skip to step 3.</div>
                        <div className="mb-3">2. If you don’t have an existing expense account, tap <b>Expense Accounts,</b> then tap <b>Create An Expense Account,</b> choose a name for your expense account and tap <b>Done.</b> </div>
                        <div className="mb-3"> 3. Tap <b>Cards</b> on the app dashboard.</div>
                        <div className="mb-3">4. Tap <b>Request A Card.</b></div>
                        <div className="mb-3">5. Choose the expense account you want to request a card for, then follow the prompts on your screen to complete your card request.</div>
                    </div>
                </span>

            </span>),
        index: 3
    },

    {
        heading: "How much does a Kuda Business expense card cost?",
        list: (
            <div className="flex flex-column  mt-2">
                <span className="faq-description f-16 mb-3">
                    We’ll charge you a one-time fee of ₦1,000 when you request a Kuda Business expense card.
                </span>

                <span className="faq-description f-16">
                    You can choose to have your expense card delivered to you anywhere in Nigeria for a fee, or you can pick up the card at no extra cost at our customer service centre in Lagos - 1-11 Commercial Avenue, Beside E-Centre, Sabo, Yaba
                </span>
            </div>),
        index: 4
    },

]


const ExpenseCards = () => {
    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])

    return (

        <Fragment>

            <InnerPageHeader
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                buttonName={entryContent.name}
                buttonUrl={entryContent.url}
                entryIllustration={EntryIllustration}
                fallback={<Img src={EntryIllustrationBlur} className="hero-illustration" />}
            />
            <DarkBelowFoldCards topFeatures={businessTopFeatures} />
            <ImageDarkTextRightWithButton
                title={expenseCard.title}
                subtitle={expenseCard.subtitle}
                illustration={expenseCard.illustration}
                name={expenseCard.name}
                url={expenseCard.url}
                isExternal={true}
            />
            <ImageDarkTextLeftWithButton
                title={AssigneeCard.title}
                subtitle={AssigneeCard.subtitle}
                illustration={AssigneeCard.illustration}
                name={AssigneeCard.name}
                url={AssigneeCard.url}
                isExternal={true}
            />
            <ImageDarkTextRightWithButton
                title={moneyMixUp.title}
                subtitle={moneyMixUp.subtitle}
                illustration={moneyMixUp.illustration}
                name={moneyMixUp.name}
                url={moneyMixUp.url}
                isExternal={true}
            />
            <ImageDarkTextLeftWithButton
                title={deliverCards.title}
                subtitle={deliverCards.subtitle}
                illustration={deliverCards.illustration}
                name={deliverCards.name}
                url={deliverCards.url}
                isExternal={true}
            />

            <FAQList title={"Kuda Business Expense Card FAQs"} questions={questions} />
            <MoreForYouComponent moreForYou={moreForYou2} />
            <CTABusiness />
        </Fragment>
    )
}

export default ExpenseCards

