import React from "react"
import ExpenseCards from "../../../components/body/pages/en-ng/business/expense-cards"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const ExpenseCardsPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/expense-card/"}
      title="Debit Cards for your Expense Accounts | Streamline Business Finances | Kuda Business"
      description="Manage your Kuda Business finances with debit cards linked to specific expense accounts. Create spaces for different expenses and streamline budgeting with unique account numbers"
    />

    <ExpenseCards />
  </Layout>
)

export default ExpenseCardsPage
